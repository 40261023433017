import { Box, Typography } from "@mui/material";

const Home = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			<Typography variant="h1">Joe Case</Typography>
			<Typography variant="h2">I make stuff</Typography>
			<Typography variant="h5">@lejoecase</Typography>
		</Box>
	);
};

export default Home;
